class Release {
  constructor({
    id = 0,
    data_criacao = 0,
    id_veiculo = 0,
    id_equipamento = 0,
    codigo = null,
    id_usuario = 0,
    observacao = null,
    descricao_motivo = null
  }) {
    this.id = id;
    this.dataCriacao = data_criacao
    this.idVeiculo = id_veiculo
    this.idEquipamento = id_equipamento;
    this.codigo = codigo;
    this.idUsuario = id_usuario;
    this.observacao = observacao
    this.descricao_motivo = descricao_motivo
  }

  toJson() {
    return {
      id: this.id,
      id_veiculo: this.idVeiculo,
      id_equipamento: this.idEquipamento,
      id_codigo: this.codigo.id,
      observacao: this.observacao,
      motivo: this.codigo.motivo,
      descricao_motivo: this.descricao_motivo
    };
  }

  getNome() {
    return {
      nome: this.nome,
    };
  }
}

export default Release;
