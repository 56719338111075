import axios from "axios";
import { url } from "../constants";

export default {
  async auth(usuario) {
    try {
      const response = await axios.post(`${url}/session`, usuario.loginJson());
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(response.data));
      }

      return true;
    } catch (error) {
      throw error;
    }
  },


  async trocarSenha(usuario) {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      await axios.put(`${url}/session`, {
        password: usuario.senha,
      },
        {
          headers: {
            Authorization: `Bearer ${token.token}`
          }
        });
    } catch (error) {
      throw error;
    }
  },

  async refresh() {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.get(`${url}/session`, {
        headers: {
          Authorization: `${token.auth} ${token.tipo}`
        }
      });

      localStorage.setItem("token", JSON.stringify(response.data));
    } catch (error) {
      localStorage.clear()
      window.location.reload()
      throw error;
    }
  }
};
