// import { action, observable } from "mobx";
export default class AlertController {
  message = null;
  snackbar = false;
  timeout = 10000;
  color = "red";
  actions = {
    accept: false,
    refresh: false
  };
  position = {
    right: true,
    left: false,
    center: true,
    top: false
  };

  context = null;

  show({
    message,
    timeout = 10000,
    color = "red",
    actions = { accept: false, refresh: false },
    position = {
      right: true,
      left: false,
      center: true,
      top: false
    }
  }) {
    this.message = message;
    this.timeout = timeout;
    this.snackbar = true;
    this.color = color;
    this.actions = actions;
    this.position = position;
  }

  confirm({
    message,
    timeout = 50000,
    color = "green",
    actions = { accept: true, refresh: false },
    position = {
      right: true,
      left: false,
      center: false,
      top: true
    }
  }) {
    this.message = message;
    this.timeout = timeout;
    this.snackbar = true;
    this.color = color;
    this.actions = actions;
    this.position = position;
  }

  refreshUpdate({
    message = "Olá! Uma nova versão do sistema está disponível.",
    timeout = 50000,
    color = "orange",
    actions = { accept: false, refresh: true },
    position = {
      right: false,
      left: true,
      center: false,
      top: false
    }
  }) {
    this.message = message;
    this.timeout = timeout;
    this.snackbar = true;
    this.color = color;
    this.actions = actions;
    this.position = position;
  }

  init(context) {
    this.context = context;
  }

  accept() {
    this.snackbar = false;
    this.context.$bus.$emit("on:accept");
  }

  refresh() {
    this.snackbar = false;
    window.location.reload();
  }
}
