import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import session from "../data/repository/session";
import User from "../domain/model/user";
import UtilController from "./util";

export default class SessionController {
  usuario = new User({});
  loading = false;
  trocarSenha = false

  async login() {
    this.loading = true;
    try {
      await session.auth(this.usuario);
      if (new UtilController().trocarSenha()) {
        this.trocarSenha = true
        this.usuario.senha = null
      } else {
        window.defaultController.setDefault(Home)
        this.usuario = new User({});
      } 
    } catch (error) {
      window.alertController.show({ message: "Usuário ou senha incorretos" });
    } finally {
      this.loading = false;
    }
  }

  async trocaSenha() {
    if (this.usuario.senha && this.usuario.senha.length > 5) {
      this.loading = true;
      try {
        await session.trocarSenha(this.usuario);
        await this.login()
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.usuario = new User({});
        this.loading = false;
      }
    } else {
      window.alertController.show({ message: "A senha precisa ter mais de 5 caracteres" });
    }
    
  }

  async logout() {
    try {
      localStorage.removeItem("token");
      window.defaultController.setDefault(Login)
      // state.commit('defaultComp/setDefault', Login, { root: true });
      // location.reload();
    } catch (error) {
      error;
    }
  }
}
