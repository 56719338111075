import vehicle from "../data/repository/vehicle";
import client from "../data/repository/client";
import equipment from "../data/repository/equipment";
import Vehicle from "../domain/model/vehicle";
import UtilController from "./util";
import onChange from "on-change";
import XLSX from "xlsx";

export default class VehicleController {
  dialog = false;
  headers = [
    {
      text: "Placa",
      align: "start",
      sortable: true,
      value: "placa"
    },
    {
      text: "Cliente",
      align: "start",
      sortable: true,
      value: "nome"
    },
    {
      text: "Modelo",
      align: "start",
      sortable: true,
      value: "modelo"
    },
    {
      text: "Ano",
      align: "start",
      sortable: true,
      value: "ano"
    },
    {
      text: "Ativo",
      align: "start",
      sortable: true,
      value: "ativo"
    },
    {
      text: "Ações",
      align: "end",
      value: "actions"
    }
  ];
  vehicles = [];
  clients = null;
  equipments = [];
  search = null;
  vehicle = new Vehicle({});
  loading = false;
  filter = 0;

  context = null;

  constructor() {
    this.watch = onChange(this, function(path, value, previousValue, name) {
      if (path == "filter") {
        this.index();
      }
    });
  }

  validateForm() {
    // if (this.context.$refs.form.validate()) {
    if (this.vehicle.id) {
      this.update();
    } else {
      this.create();
    }
    // }
  }

  init(context) {
    this.context = context;

    this.context.$bus.$on("on:accept", async () => {
      try {
        await vehicle.delete(this.vehicle);
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.index();
      }
    });
  }

  async getCityes() {
    try {
      this.cityes = await citye.index(this.city);
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async create() {
    try {
      this.loading = true;
      // await vehicle.create(this.vehicle);

      if (this.clients == null) {
        this.vehicle.idCliente = new UtilController().getIDClient();
      }

      await vehicle.create(this.vehicle);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async update() {
    try {
      this.loading = true;
      await vehicle.update(this.vehicle);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async deleteItem(item) {
    this.vehicle = new Vehicle(item);
    window.alertController.confirm({ message: "Deseja realmente excluir o registro?" });
  }

  async index() {
    try {
      this.vehicles = await vehicle.index(this.filter);

      const { vehicles } = new UtilController().getItemsVisualizacoesUsuario();

      if (vehicles.length > 0) {
        this.vehicles = this.vehicles.filter(vehicle => vehicles.includes(vehicle.id));
      }
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setVehicle(vehicle) {
    try {
      const clients = await client.index(0);

      this.clients = clients;
    } catch (error) {
      this.clients = null;
    }

    try {
      const equipments = await equipment.index(0);
      this.equipments = equipments;

      if (vehicle) {
        this.vehicle = new Vehicle(vehicle);
      } else {
        this.vehicle = new Vehicle({});
      }

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  xlsx() {
    var dados = XLSX.utils.json_to_sheet(this.vehicles);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dados, "dados");
    XLSX.writeFile(wb, `veiculos.xlsx`);
  }
}
