<template>
  <v-app>
    <v-app-bar dense clipped-left app color="primary" dark fixed>
      <v-app-bar-nav-icon @click="handlerNav"></v-app-bar-nav-icon>
      <!-- <v-avatar>
        <v-img contain src="/logo_empire.jpg"></v-img>
      </v-avatar>-->

      <v-toolbar-title class="pl-5">{{ utilController.getUserEmp() }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <component :is="homeController.default.comp"></component>
    </v-main>
    <div  @mouseenter="mini = false" @mouseleave="mini = true">
      <v-navigation-drawer app clipped left v-model="drawer" :mini-variant="mini" :permanent="!$vuetify.breakpoint.mobile" :temporary="$vuetify.breakpoint.mobile">
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>{{utilController.getUsuario()}}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense >
          <v-list-group
            mandatory
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            :append-icon="item.children ? 'mdi-chevron-down' : ''"
            no-action
            @click="item.title == 'Sair' ? sessionController.logout() :
                !item.children ? homeController.setDefault(item.component) : ''"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              @click="homeController.setDefault(child.component)"
            >
              <v-list-item-icon>
                <v-icon>{{child.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- </v-list-item-group> -->
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </v-app>
</template>

<script>
import UtilController from "@/mobx/store/util";
import SessionController from "@/mobx/store/session";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Cliente from "@/views/Client.vue";
import Equipment from "./Equipment.vue";
import ModelVue from "./Model.vue";
import VehicleVue from "./Vehicle.vue";
import CodeVue from "./Code.vue";
import ReleaseVue from "./Release.vue";
import UserVue from './User.vue';

export default {
  data: vm => ({
    model: 0,
    login: Login,
    component: vm.home,
    drawer: false,
    items: [],
    mini: false,
    homeController: window.homeController,
    utilController: new UtilController(),
    sessionController: new SessionController()
  }),
  // watch: {
  //   home(val) {
  //     this.component = val;
  //   }
  // },
  async mounted() {
    this.component = this.home;

    if (
      this.utilController.checkPermissions(2, 4) ||
      this.utilController.checkPermissions(4, 4) ||
      this.utilController.checkPermissions(1, 4)
    ) {
      this.items.push({
        title: "Home",
        icon: "mdi-home-city",
        component: Dashboard
      });
    }

    if (
      this.utilController.checkPermissions(2, 4) ||
      this.utilController.checkPermissions(4, 4) ||
      this.utilController.checkPermissions(3, 4) ||
      this.utilController.checkPermissions(5, 4) ||
      this.utilController.checkPermissions(1, 4)
    ) {
      this.items.push({
        title: "Cadastros",
        icon: "mdi-cogs",
        children: []
      });

      if (this.utilController.checkPermissions(5, 4)) {
        this.items[1].children.push({
          title: "Redes",
          icon: "mdi-barcode-scan",
          component: CodeVue
        });
      }

      if (this.utilController.checkPermissions(1, 4)) {
        this.items[1].children.push({
          title: "Clientes",
          icon: "mdi-account",
          component: Cliente
        });
      }

      if (this.utilController.checkPermissions(3, 4)) {
        this.items[1].children.push({
          title: "Modelos",
          icon: "mdi-inbox",
          component: ModelVue
        });
      }

      if (this.utilController.checkPermissions(4, 4)) {
        this.items[1].children.push({
          title: "Veículos",
          icon: "mdi-truck",
          component: VehicleVue
        });
      }

      if (this.utilController.checkPermissions(2, 4)) {
        this.items[1].children.push({
          title: "Equipamentos",
          icon: "mdi-access-point-network",
          component: Equipment
        });
      }

      if (this.utilController.checkPermissions(7, 4)) {
        this.items[1].children.push({
          title: "Usuários",
          icon: "mdi-account",
          component: UserVue
        });
      }
    }

    if (this.utilController.checkPermissions(6, 4)) {
      this.items.push({
        title: "Relatórios",
        icon: "mdi-file",
        children: [
          {
            title: "Liberações",
            icon: "mdi-barcode-scan",
            component: ReleaseVue
          }
        ]
      });
    }

    this.items.push({ title: "Sair", icon: "mdi-exit-to-app" });
  },
  methods: {
    handlerNav() {
      if (this.$vuetify.breakpoint.mobile) {
        this.mini = false;
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini; 
      }
      
    },
    // setDefault(component) {
    //   this.drawer = false
    //   this.$store.commit("home/setDefault", component);
    // },
    // ...mapActions("session", ["logout"])
  }
};
</script>
