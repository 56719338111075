<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <span class="pr-4">Veículos</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar veículo"
                single-line
                filled
                dense
                hide-details
                v-model="vehicleController.search"
              ></v-text-field>
              <v-btn
                v-if="!vehicleController.loading"
                @click="vehicleController.xlsx()"
                color="green ml-3 white--text"
              >
                <v-icon left>mdi-file-excel-outline</v-icon>Excel
              </v-btn>
              <v-btn
                v-if="utilController.checkPermissions(4, 1)"
                @click="vehicleController.setVehicle(null)"
                color="primary ml-3"
              >
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn dark icon v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item-group v-model="vehicleController.watch.filter" mandatory>
                    <v-list-item>
                      <v-list-item-title>Ativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Inativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Todos</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="75vh"
              :headers="vehicleController.headers"
              :items="vehicleController.vehicles"
              :search="vehicleController.search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina'
              }"
            >
              <template v-slot:[`item.ativo`]="{ item }">
                <v-checkbox readonly v-model="item.ativo" hide-details dense class="mt-0"></v-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="orange"
                  v-if="item.ativo && utilController.checkPermissions(6, 1)"
                  small
                  class="mr-2"
                  @click="releaseController.setCode({ id: item.id })"
                  >mdi-qrcode</v-icon
                >
                <v-icon
                  color="blue"
                  v-if="utilController.checkPermissions(4, 2)"
                  @click="vehicleController.setVehicle(item)"
                  small
                  class="mr-2"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon color="red" v-if="utilController.checkPermissions(4, 3)" small @click="vehicleController.deleteItem(item)">mdi-delete</v-icon> -->
              </template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh;">Sem dados</v-row>
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="vehicleController.dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline"
          >{{ vehicleController.vehicle.id !== 0 ? "Editar" : "Novo" }} Veículo</v-card-title
        >
        <v-card-text>
          <v-checkbox dense v-model="vehicleController.vehicle.ativo" label="Veículo ativo"></v-checkbox>
          <v-text-field dense filled v-model="vehicleController.vehicle.placa" label="Placa"></v-text-field>
          <v-select
            v-if="vehicleController.clients"
            item-text="nome"
            item-value="id"
            filled
            dense
            v-model="vehicleController.vehicle.idCliente"
            :items="vehicleController.clients"
            label="Cliente"
          ></v-select>
          <v-text-field dense filled v-model="vehicleController.vehicle.modelo" label="Modelo"></v-text-field>
          <v-text-field
            dense
            type="number"
            filled
            v-model="vehicleController.vehicle.ano"
            label="Ano"
          ></v-text-field>
          <v-autocomplete
            filled
            dense
            label="Equipamentos"
            multiple
            :items="vehicleController.equipments"
            v-model="vehicleController.vehicle.equipamentos"
            item-text="nome"
            item-value="id"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-btn color="red" text @click="vehicleController.dialog = false"
            ><v-icon>mdi-close</v-icon> Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="vehicleController.validateForm()"
            color="primary"
            :loading="vehicleController.loading"
            ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-release :releaseController="releaseController" />
  </v-app>
</template>

<script>
import CreateRelease from "@/components/CreateRelease.vue";

import VehicleController from "@/mobx/store/vehicle";
import ReleaseController from "@/mobx/store/release";
import UtilController from "@/mobx/store/util";

export default {
  data: () => ({
    vehicleController: new VehicleController(),
    releaseController: new ReleaseController(),
    utilController: new UtilController()
  }),
  mounted() {
    this.vehicleController.init(this);
    this.vehicleController.index();
  },
  components: {
    "create-release": CreateRelease
  }
  // computed: {
  //   ...mapState({
  //     dialog: state => state.vehicle.dialog,
  //     headers: state => state.vehicle.headers,
  //     vehicles: state => state.vehicle.vehicles,
  //     search: state => state.vehicle.search,
  //     loading: state => state.vehicle.loading,
  //     vehicle: state => state.vehicle.vehicle,
  //     clients: state => state.vehicle.clients,
  //     filter: state => state.vehicle.filter,
  //     snackbar: state => state.vehicle.snackbar,
  //     equipments: state => state.vehicle.equipments,
  //     util: state => state.home.util
  //   })
  // },
  // mounted() {
  //   this.index();
  // },
  // watch: {
  //   "filter.val": function() {
  //     (this);

  //     this.index();
  //   }
  // },
  // methods: {
  //   ...mapActions("vehicle", ["login", "showDialog", "create", "index", "update", "deleteItem", "set"]),
  //   ...mapActions("release", ["showDialogCode", "setCode", "confirm"])
  // }
};
</script>
