<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <v-progress-circular
                v-if="equipmentController.loading"
                class="mr-4"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div class="text-left">
                <span class="pr-4">Equipamentos</span>
                <br />
                <span class="mr-10" style="font-size: 15px"
                  >Com placa: {{ equipmentController.comPlaca }}</span
                >
                <span style="font-size: 15px"
                  >Sem placa: {{ equipmentController.semPlaca }}</span
                >
              </div>

              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar equipamento"
                single-line
                filled
                dense
                v-model="equipmentController.watch.search"
                hide-details
              ></v-text-field>

              <v-btn
                v-if="
                  equipmentController.habilitaTrocarCliente &&
                  utilController.checkPermissions(2, 2)
                "
                @click="equipmentController.atualizarCliente()"
                color="secondary ml-3"
              >
                <v-icon left>mdi-account-convert</v-icon>Atualizar cliente
              </v-btn>
              <v-btn
                v-if="
                  !equipmentController.loading
                "
                @click="equipmentController.xlsx()"
                color="green ml-3 white--text"
              >
                <v-icon left>mdi-file-excel-outline</v-icon>Excel
              </v-btn>
              <v-btn
                v-if="
                  equipmentController.selecionados.length >= 4 &&
                  utilController.checkPermissions(2, 2)
                "
                @click="equipmentController.qrCode()"
                color="red ml-3"
                class="white--text"
              >
                <v-icon left>mdi-qrcode</v-icon>Imprimir QRCode
              </v-btn>
              <v-btn
                v-if="
                  !equipmentController.loading &&
                  utilController.checkPermissions(2, 1)
                "
                @click="equipmentController.setEquipment(null)"
                color="primary ml-3"
              >
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn dark icon v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item-group
                    v-model="equipmentController.watch.filter"
                    mandatory
                  >
                    <v-list-item>
                      <v-list-item-title>Ativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Inativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Todos</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-data-table
              calculate-widths
              dense
              fixed-header
              height="73vh"
              :headers="equipmentController.headers"
              :items="equipmentController.equipments"
              :search="equipmentController.search"
              sort-by="calories"
              class="elevation-1"
              show-select
              v-model="equipmentController.watch.selecionados"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina',
              }"
            >
              <template v-slot:[`item.data_criacao`]="{ item }">{{
                $moment.utc(item.data_criacao).format("DD/MM/YYYY HH:mm")
              }}</template>
              <template v-slot:[`item.data_alteracao_placa`]="{ item }">{{
                item.data_alteracao_placa ? $moment.utc(item.data_alteracao_placa)
                  .format("DD/MM/YYYY HH:mm") : ''
              }}</template>
              <template v-slot:[`item.ativo`]="{ item }">
                <v-checkbox
                  readonly
                  v-model="item.ativo"
                  hide-details
                  dense
                  class="mt-0"
                ></v-checkbox>
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
                v-if="!equipmentController.loading"
              >
                <v-icon
                  color="green"
                  v-if="item.ativo && utilController.checkPermissions(8, 2)"
                  small
                  class="mr-2"
                  @click="equipmentController.setPlaca(item)"
                  >mdi-train-car</v-icon
                >
                <v-icon
                  color="orange"
                  v-if="item.ativo && utilController.checkPermissions(6, 1)"
                  small
                  class="mr-2"
                  @click="releaseController.setCode({ value: item })"
                  >mdi-qrcode</v-icon
                >
                <v-icon
                  color="blue"
                  v-if="utilController.checkPermissions(2, 2)"
                  small
                  class="mr-2"
                  @click="equipmentController.setEquipment(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  color="red"
                  v-if="utilController.checkPermissions(2, 3)"
                  @click="equipmentController.deleteItem(item)"
                  small
                  >mdi-delete</v-icon
                >
              </template>

              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh"
                    >Sem dados</v-row
                  >
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="equipmentController.dialog" persistent max-width="500">
      <v-card>
        <v-form ref="form">
          <v-card-title class="headline">Novo Equipamento</v-card-title>
          <v-card-text>
            <v-checkbox
              dense
              v-model="equipmentController.equipment.ativo"
              label="Equipamento ativo"
            ></v-checkbox>
            <v-text-field
              dense
              v-mask="equipmentController.mask"
              :rules="equipmentController.rules"
              filled
              v-model="equipmentController.equipment.nome"
              label="Serial"
            ></v-text-field>
            <v-select
              dense
              item-text="nome"
              item-value="id"
              filled
              :rules="[(v) => !!v || 'Escolha o modelo']"
              v-model="equipmentController.equipment.idModelo"
              :items="equipmentController.models"
              label="Modelo"
            ></v-select>
            <v-autocomplete
              dense
              item-text="placa"
              item-value="id"
              filled
              clearable
              v-model="equipmentController.equipment.idVeiculo"
              :items="equipmentController.vehicles"
              label="Veículo"
              v-if="
                utilController.checkField(2, 1, 'veiculo') ||
                utilController.checkField(2, 2, 'veiculo')
              "
            ></v-autocomplete>
            <v-select
              dense
              item-text="nome"
              item-value="id"
              filled
              v-model="equipmentController.watch.equipment.idTipoEquipamento"
              :items="equipmentController.types"
              label="Tipo do equipamento"
              :rules="[(v) => !!v || 'Escolha o tipo do equipamento']"
            ></v-select>
            <v-text-field
              dense
              :rules="[(v) => !!v || 'Preencha o mac do equipamento']"
              v-if="equipmentController.showMac"
              filled
              v-model="equipmentController.equipment.mac"
              label="Endereço MAC"
            ></v-text-field>
            <v-select
              dense
              v-if="
                equipmentController.clients &&
                utilController.checkField(2, 1, 'cliente')
              "
              item-text="nome"
              item-value="id"
              filled
              v-model="equipmentController.equipment.idCliente"
              :items="equipmentController.clients"
              label="Cliente"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" text @click="equipmentController.dialog = false"
              ><v-icon left>mdi-close</v-icon> Cancelar</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              @click="equipmentController.validateForm()"
              color="primary"
              :loading="equipmentController.loading"
              ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="equipmentController.dialogPlaca"
      persistent
      max-width="500"
    >
      <v-card>
        <v-form ref="form">
          <v-card-title class="headline">Alterar veículo</v-card-title>
          <v-card-text>
            <v-autocomplete
              dense
              item-text="placa"
              item-value="id"
              filled
              clearable
              v-model="equipmentController.equipment.idVeiculo"
              :items="equipmentController.vehicles"
              label="Veículo"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="red"
              text
              @click="equipmentController.dialogPlaca = false"
              ><v-icon left>mdi-close</v-icon> Cancelar</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn
              @click="equipmentController.updatePlate()"
              color="primary"
              :loading="equipmentController.loading"
              ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="equipmentController.dialogCliente"
      persistent
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="headline">Atualizar cliente</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-autocomplete
              dense
              item-text="nome"
              item-value="id"
              filled
              clearable
              v-model="equipmentController.idCliente"
              :items="equipmentController.comboClients"
              label="Cliente"
            ></v-autocomplete>
          </v-form>
          <v-card outlined class="mt-4">
            <v-card-title>Equipamentos para o cliente</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Serial</th>
                      <th class="text-left">Modelo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        selecionado, key
                      ) in equipmentController.selecionados"
                      :key="key"
                    >
                      <td>{{ selecionado.nome }}</td>
                      <td>{{ selecionado.modelo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="equipmentController.dialogCliente = false"
            ><v-icon left>mdi-close</v-icon> Cancelar</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            @click="equipmentController.updateClient()"
            color="primary"
            :loading="equipmentController.loading"
            ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="720" v-model="equipmentController.dialogQRCode">
      <v-card>
        <v-card-title>
          <v-btn @click="equipmentController.printElem('printQR')"
            >IMPRIMIR</v-btn
          >
        </v-card-title>
        <v-card-text id="printQR">
          <v-row>
            <v-col
              v-for="(item, i) in equipmentController.selecionados"
              :key="i"
              cols="4"
            >
              <v-card width="250" outlined height="160">
                <v-card-text class="pa-0">
                  <div>
                    <div class="px-4 font-weight-bold">
                      Serial: {{ item.nome }}
                    </div>
                    <div style="float: left">
                      <vue-qrcode :value="item.nome" />
                    </div>
                    <div style="float: left">
                      <img
                        style="margin-top: 40px"
                        width="60"
                        src="snow"
                        alt=""
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <create-release :releaseController="releaseController" />
  </v-app>
</template>

<script>
import VueQrcode from "vue-qrcode";
import CreateRelease from "@/components/CreateRelease.vue";
import EquipmentController from "@/mobx/store/equipment";
import ReleaseController from "@/mobx/store/release";
import utilControllerController from "@/mobx/store/util";

export default {
  components: {
    "create-release": CreateRelease,
    VueQrcode,
  },
  data: () => ({
    equipmentController: new EquipmentController(),
    releaseController: new ReleaseController(),
    utilController: new utilControllerController(),
  }),
  mounted() {
    this.equipmentController.init(this);
    this.equipmentController.index();
  },
};
</script>