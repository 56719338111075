<template>
  <component :is="defaultController.default.comp"></component>
</template>

<script>
import UtilController from "../mobx/store/util";
import HomeVue from "./Home.vue";

export default {
  data: () => ({
    defaultController: window.defaultController,
    utilController: new UtilController(),
  }),
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      if (!this.utilController.trocarSenha()) {
        this.defaultController.setDefault(HomeVue);
      } else {
        localStorage.clear()
      }
    }
  },
};
</script>
