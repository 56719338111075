<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <span class="pr-4">Usuários</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar usuário"
                single-line
                v-model="userController.search"
                filled
                dense
                hide-details
              ></v-text-field>
              <v-btn
                v-if="utilController.checkPermissions(7, 1)"
                color="primary ml-3"
                @click="userController.setUser(null)"
              >
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn dark icon v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item-group v-model="userController.filter" mandatory>
                    <v-list-item>
                      <v-list-item-title>Ativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Inativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Todos</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="75vh"
              :headers="userController.headers"
              :items="userController.users"
              :search="userController.search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina'
              }"
            >
              <template v-slot:[`item.ativo`]="{ item }">
                <v-checkbox readonly v-model="item.ativo" hide-details dense class="mt-0"></v-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="userController.getVisualizacoes(item)" class="mr-2" color="orange" small
                  >mdi-account-key</v-icon
                >
                <v-icon
                  color="blue"
                  v-if="utilController.checkPermissions(7, 2)"
                  small
                  @click="userController.setUser(item)"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon
                  v-if="util.checkPermissions(7, 3)"
                  small
                  @click="snackbar.val = true; snackbar.obj = item;"
                >mdi-delete</v-icon> -->
              </template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh;">Sem dados</v-row>
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="userController.dialog" persistent max-width="500">
      <v-card>
        <v-form ref="form">
          <v-card-title class="headline"
            >{{ userController.users.id !== 0 ? "Editar" : "Novo" }} usuário</v-card-title
          >
          <v-card-text>
            <v-checkbox v-model="userController.user.revogado" label="Usuário bloqueado"></v-checkbox>
            <v-checkbox v-model="userController.user.trocarSenha" label="Trocar a senha no próximo acesso"></v-checkbox>
            <v-text-field
              dense
              :rules="[v => !!v || 'Preencha o nome']"
              filled
              v-model="userController.user.nomeUsuario"
              label="Nome do usuário"
            ></v-text-field>
            <v-text-field
              dense
              :rules="[v => !!v || 'Preencha o e-mail']"
              filled
              v-model="userController.user.email"
              label="E-mail"
            ></v-text-field>
            <v-text-field
              dense
              :rules="userController.senhaRules"
              filled
              v-model="userController.user.senha"
              label="Senha"
              type="password"
            ></v-text-field>
            <v-select
              dense
              item-text="nome"
              item-value="id"
              filled
              v-model="userController.user.idCliente"
              :items="userController.clients"
              label="Cliente"
              clearable
            ></v-select>
            <v-select
              dense
              item-text="nome"
              item-value="id"
              filled
              v-model="userController.user.idGrupoUsuario"
              :items="userController.grupos"
              label="Grupo usuário"
              :rules="[v => !!v || 'Preencha o grupo do usuário']"
            ></v-select>
          </v-card-text>
          <v-card-actions class="mx-2">
            <v-btn color="red" text @click="userController.dialog = false"
              ><v-icon left>mdi-close</v-icon> Cancelar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="userController.loading" @click="userController.validateForm()"
              ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="1000" v-model="userController.dialogVisualzacoes">
      <v-card>
        <v-card-title>
          Veículos e equipamentos visíveis para o usuário {{ userController.user.nomeUsuario }}.
        </v-card-title>
        <v-card-text>
          <v-container class="px-0" grid-list-md fluid>
            <v-layout>
              <v-flex xs6>
                <v-card>
                  <v-data-table
                    color="primary"
                    dense
                    show-select
                    fixed-header
                    height="60vh"
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="userController.vehicles"
                    :headers="userController.visualizacaoHeader"
                    v-model="userController.selectedVehicles"
                    item-key="id"
                  ></v-data-table>
                </v-card>
              </v-flex>
              <v-flex xs6>
                <v-card>
                  <v-data-table
                    color="primary"
                    dense
                    show-select
                    fixed-header
                    height="60vh"
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="userController.equipments"
                    :headers="userController.visualizacaoHeadeEquipament"
                    v-model="userController.selectedEquipments"
                    item-key="id"
                  ></v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-btn color="red" text @click="userController.dialogVisualzacoes = false"
            ><v-icon left>mdi-close</v-icon> Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="userController.loading" @click="userController.updateVisualizations()"
            ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import UtilController from "@/mobx/store/util";
import UserController from "@/mobx/store/user";

export default {
  data: () => ({
    userController: new UserController(),
    utilController: new UtilController()
  }),
  mounted() {
    this.userController.init(this);
    this.userController.index();
  },
};
</script>
