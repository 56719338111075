import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async index(city) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/city?city=${city}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            if (token !== undefined) {
              await session.refresh();
              return await this.index(city);
            }
            throw error;
  
          default:
            throw error;
        }
      } 

      throw error;
    }
  }
};
