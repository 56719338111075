<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <span class="pr-4">Modelos de equipamento</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar modelo"
                single-line
                filled
                dense
                hide-details
                v-model="modelController.search"
              ></v-text-field>
              <v-btn
                v-if="utilController.checkPermissions(3, 1)"
                color="primary ml-3"
                @click="modelController.setModel(null)"
              >
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="75vh"
              :headers="modelController.headers"
              :items="modelController.models"
              :search="modelController.search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina',
              }"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="blue"
                  v-if="utilController.checkPermissions(3, 2)"
                  small
                  class="mr-2"
                  @click="modelController.setModel(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  color="red"
                  v-if="utilController.checkPermissions(3, 3)"
                  small
                  @click="modelController.deleteItem(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh"
                    >Sem dados</v-row
                  >
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      scrollable
      v-model="modelController.dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Novo modelo</v-card-title>
        <v-card-text>
          <v-form ref="formModule">
            <v-text-field
              dense
              filled
              v-model="modelController.model.nome"
              :rules="[(v) => !!v || 'Preencha o nome']"
              label="Nome"
            ></v-text-field>
            <v-text-field
              dense
              filled
              v-model="modelController.model.versaoFirmware"
              label="Versão firmware"
            ></v-text-field>
            <v-textarea
              dense
              filled
              v-model="modelController.model.descricaoUso"
              label="Descrição de uso do modelo"
              :rules="[
                (v) => !!v || 'Preencha o campo',
                (v) =>
                  !!v && v.length <= 1000 ||
                  'A descrição não pode ter mais que 1000 caracteres',
              ]"
              :counter="1000"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-btn color="red" text @click="modelController.dialog = false"
            ><v-icon>mdi-close</v-icon> Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="modelController.loading"
            @click="modelController.validateForm()"
            ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ModelController from "@/mobx/store/model";
import UtilController from "@/mobx/store/util";

export default {
  data: () => ({
    modelController: new ModelController(),
    utilController: new UtilController(),
  }),
  mounted() {
    this.modelController.init(this);
    this.modelController.index();
  },
};
</script>
