<template>
  <v-app>
    <v-container fluid>
      <v-layout
        justify-center
        align-center
      >
        <v-flex
          x12
          class="text-center"
        >
          <v-card>
            <v-card-title>
              <span class="pr-4">Clientes</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar cliente"
                single-line
                v-model="clientController.search"
                filled
                dense
                hide-details
              ></v-text-field>
              <v-btn
                v-if="utilController.checkPermissions(1, 1)"
                color="primary ml-3"
                @click="
                  clientController.setClient(null);
                  clientController.dialog = true
                "
              >
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item-group
                    v-model="clientController.watch.filter"
                    mandatory
                  >
                    <v-list-item>
                      <v-list-item-title>Ativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Inativos</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Todos</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="75vh"
              :headers="clientController.headers"
              :items="clientController.clients"
              :search="clientController.search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina'
              }"
            >
              <template v-slot:[`item.ativo`]="{ item }">
                <v-checkbox
                  readonly
                  v-model="item.ativo"
                  hide-details
                  dense
                    
                ></v-checkbox>
              </template>
              <template v-slot:[`item.associado`]="{ item }">
                <v-checkbox
                  readonly
                  v-model="item.associado"
                  hide-details
                  dense
                  class="mt-0"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="blue"
                  v-if="utilController.checkPermissions(1, 2)"
                  small
                  class="mr-2"
                  @click="clientController.setClient(item)"
                >mdi-pencil</v-icon>
                <v-icon
                  color="red"
                  @click="clientController.deleteItem(item)"
                  v-if="utilController.checkPermissions(1, 3)"
                  small
                >mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row
                    align="center"
                    justify="center"
                    style="height: 66vh;"
                  >Sem dados</v-row>
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="clientController.dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-form
          ref="form"
          autocomplete="null"
        >
          <v-card-title class="headline">{{ clientController.client.id !== 0 ? "Editar" : "Novo" }} cliente</v-card-title>
          <v-card-text>
            <v-checkbox
              hide-details
              v-model="clientController.client.ativo"
              label="Cliente ativo"
            ></v-checkbox>
            <v-checkbox
              class="pb-5"
              hide-details
              v-model="clientController.client.associado"
              label="Cliente associado"
            ></v-checkbox>

            <v-text-field
              v-if="clientController.client.associado"
              :rules="[v => !!v || 'Preencha o nome']"
              filled
              v-model="clientController.client.nome_associacao"
              label="Associação"
              dense
            ></v-text-field>

            <v-text-field
              :rules="[v => !!v || 'Preencha o nome']"
              filled
              v-model="clientController.client.nome"
              label="Nome"
              dense
            ></v-text-field>
            <v-text-field
              :rules="[v => !!v || 'Preencha o CPF/CNPJ']"
              v-mask="clientController.cpfcnpj"
              filled
              v-model="clientController.client.cpfCnpj"
              label="CPF/CNPJ"
              dense
            ></v-text-field>
            <v-text-field
              v-mask="clientController.telefone"
              filled
              v-model="clientController.client.telefone"
              label="Telefone"
            ></v-text-field>
            <v-text-field
              filled
              v-model="clientController.client.endereco"
              label="Endereço"
            ></v-text-field>
            <v-autocomplete
              v-model="clientController.client.idCidade"
              autocomplete="null"
              filled
              :items="clientController.cityes"
              :search-input.sync="clientController.city"
              @keyup="clientController.getCityes()"
              item-text="nome"
              item-value="id"
              label="Cidade"
              no-data-text="Pesquise a cidade"
              dense
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="mx-1">
            <v-btn
              color="red"
              text
              @click="clientController.dialog = false"
            >
              <v-icon left>mdi-close</v-icon>Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="clientController.loading"
              @click="clientController.validateForm()"
            >
              <v-icon left>mdi-content-save</v-icon> Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      top
      right
      color="red"
    >
      Deseja realmente excluir o registro?
      <v-btn
        color="white"
        text
        @click="clientController.deleteItem"
      >Sim</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import ClientController from "../mobx/store/client";
import UtilController from "../mobx/store/util";

export default {
  data: () => ({
    clientController: new ClientController(),
    utilController: new UtilController(),
  }),
  mounted() {
    this.clientController.init(this);
    this.clientController.index();
  },
};
</script>
