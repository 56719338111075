class Client {
  constructor({
    id = 0,
    nome = null,
    cpf_cnpj = null,
    telefone = null,
    endereco = null,
    id_cidade = null,
    cidade = '',
    ativo = true,
    qtd_veiculos = 0,
    associado = false,
    nome_associacao = null
  }) {
    this.id = id;
    this.nome = nome;
    this.cpfCnpj = cpf_cnpj;
    this.telefone = telefone;
    this.endereco = endereco;
    this.idCidade = id_cidade;
    this.cidade = cidade;
    this.ativo = ativo;
    this.qtdVeiculos = qtd_veiculos;
    this.associado = associado
    this.nome_associacao = nome_associacao
  }

  toJson() {
    return {
      id: this.id,
      nome: this.nome,
      cpf_cnpj: this.cpfCnpj,
      telefone: this.telefone,
      endereco: this.endereco,
      id_cidade: this.idCidade,
      ativo: this.ativo,
      associado: this.associado,
      nome_associacao: this.nome_associacao
    };
  }

  getNome() {
    return {
      nome: this.nome,
    };
  }
}

export default Client;
