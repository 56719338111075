class Model {
  constructor({
    id = 0,
    nome = null,
    descricao_uso = null,
    versao_firmware = null
  }) {
    this.id = id;
    this.nome = nome;
    this.descricaoUso = descricao_uso
    this.versaoFirmware = versao_firmware
  }

  toJson() {
    return {
      id: this.id,
      nome: this.nome,
      descricaoUso: this.descricaoUso,
      versaoFirmware: this.versaoFirmware
    };
  }

  getNome() {
    return {
      nome: this.nome,
    };
  }
}

export default Model;
