import userRepository from "../data/repository/user";
import User from "../domain/model/user";
import client from "../data/repository/client";
import equipment from "../data/repository/equipment";
import vehicle from "../data/repository/vehicle";
// import UtilController from "@/mobx/store/util";

export default class UserController {
  dialog = false;
  dialogVisualzacoes = false;
  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "nome_usuario"
    },
    {
      text: "E-mail",
      align: "start",
      sortable: true,
      value: "email"
    },
    {
      text: "Grupo usuário",
      align: "start",
      sortable: true,
      value: "grupo_usuario"
    },
    {
      text: "Cliente",
      align: "start",
      sortable: true,
      value: "nome_cliente"
    },
    {
      text: "Ações",
      align: "end",
      value: "actions"
    }
  ];

  visualizacaoHeader = [
    {
      text: "Placas",
      align: "start",
      value: "placa"
    }
  ];

  visualizacaoHeadeEquipament = [
    {
      text: "Equipamentos",
      align: "start",
      value: "nome"
    }
  ];
  users = [];
  vehicles = [];
  selectedVehicles = [];
  equipments = [];
  selectedEquipments = [];
  clients = [];
  search = null;
  user = new User({});
  loading = false;
  filter = 0;

  // util = new UtilController();

  senhaRules = [v => this.user.id != 0 || !!v || "Preencha a senha"];
  grupos = [
    { id: 1, nome: "Administrador" },
    { id: 2, nome: "Cliente administrador" },
    { id: 3, nome: "Cliente visualizador" },
    { id: 6, nome: "Cliente Produção" },
    { id: 4, nome: "Técnico" },
    { id: 5, nome: "Produção" }
  ];

  context = null;

  validateForm() {
    if (this.context.$refs.form.validate()) {
      if (this.user.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  init(context) {
    this.context = context;
  }

  async getVisualizacoes(item) {
    try {
      this.user = new User(item);
      const [vehicles, equipments] = await Promise.all([vehicle.index(0), equipment.index(0)]);

      this.vehicles = vehicles;
      this.equipments = equipments;

      const selectedVehicles = this.user?.veiculosVisiveis?.split(",")?.map(item => parseInt(item)) ?? [];
      const selectedEquipments =
        this.user?.equipamentosVisiveis?.split(",")?.map(item => parseInt(item)) ?? [];

      this.selectedVehicles = [];
      this.selectedEquipments = [];

      for (let vehicle of this.vehicles) {
        selectedVehicles.forEach(item => {
          if (vehicle.id == item) {
            this.selectedVehicles.push(vehicle);
          }
        });
      }

      for (let equipment of this.equipments) {
        selectedEquipments.forEach(item => {
          if (equipment.id == item) {
            this.selectedEquipments.push(equipment);
          }
        });
      }

      this.dialogVisualzacoes = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async updateVisualizations() {
    try {
      this.loading = true;
      const payload = {
        equipamentos:
          this.selectedEquipments.length == this.equipments.length
            ? null
            : this.selectedEquipments.map(equipment => equipment.id).join(","),
        veiculos:
          this.selectedVehicles.length == this.vehicles.length
            ? null
            : this.selectedVehicles.map(vehicle => vehicle.id).join(",")
      };
      await userRepository.updateVisualizations(payload, this.user.id);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.dialogVisualzacoes = false;
      this.index();
      this.loading = false;
    }
  }

  async create() {
    try {
      this.loading = true;
      await userRepository.create(this.user);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async update() {
    try {
      this.loading = true;
      await userRepository.update(this.user);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async index() {
    try {
      this.users = await userRepository.index();
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setUser(user) {
    try {
      this.clients = await client.index(0);
      if (user) {
        this.user = new User(user);
      } else {
        this.user = new User({});
      }

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }
}
