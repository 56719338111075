<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <span class="pr-4">Códigos</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar código"
                single-line
                filled
                v-model="codeController.search"
                dense
                hide-details
              ></v-text-field>
              <v-btn v-if="utilController.checkPermissions(5, 1)" color="primary ml-3" @click="codeController.setCode(null)">
                <v-icon left>mdi-plus</v-icon>Novo
              </v-btn>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="75vh"
              :headers="codeController.headers"
              :items="codeController.codes"
              :search="codeController.search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [30, 60, 100],
                itemsPerPageText: 'Linhas por pagina'
              }"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="blue" v-if="utilController.checkPermissions(5, 2)" small class="mr-2" @click="codeController.setCode(item)">mdi-pencil</v-icon>
                <v-icon color="red" v-if="utilController.checkPermissions(5, 3)" small @click="codeController.deleteItem(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh;">Sem dados</v-row>
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="codeController.dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ codeController.code.id !== 0 ? 'Editar': 'Novo' }} código</v-card-title>
        <v-card-text>
          <v-text-field dense filled v-model="codeController.code.nome" label="Código"></v-text-field>
          <v-text-field dense filled v-model="codeController.code.senha" label="Senha"></v-text-field>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-btn color="red" text @click="codeController.dialog = false">
            <v-icon>mdi-close</v-icon>
           Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="codeController.loading"
            @click="codeController.validateForm()"
          ><v-icon left>mdi-content-save</v-icon> Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CodeController from "../mobx/store/code";
import UtilController from "../mobx/store/util";

export default {
  data: () => ({
    codeController: new CodeController(),
    utilController: new UtilController()
  }),
  mounted() {
    this.codeController.init(this)
    this.codeController.index()
  }
};
</script>
