<template>
  <div id="app">
    <v-app>
      <router-view />
      <v-snackbar
        :color="alert.color"
        v-model="alert.snackbar"
        :right="alert.position.right"
        :left="alert.position.left"
        :center="alert.position.center"
        :top="alert.position.top"
        multi-line
        :timeout="alert.timeout"
      >
        {{ alert.message }}

        <v-btn v-show="alert.actions.refresh" text @click="alert.refresh()">
          Recarregar
        </v-btn>
        <template v-slot:action="{ attrs }">
          <v-btn v-show="alert.actions.accept" text v-bind="attrs" @click="alert.accept()">
            Sim
          </v-btn>
        </template>
        <v-spacer></v-spacer>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
export default {
  data: () => ({
    alert: window.alertController,
  }),
  mounted() {
    this.alert.init(this);
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@media print {
  * {
    display: inline;
  }
  script,
  style {
    display: none;
  }
  div {
    page-break-inside: avoid;
  }
}
</style>
