<template>
  <div>
    <v-dialog
      v-model="releaseController.dialog"
      persistent
      max-width="500"
    >
      <v-card v-if="!releaseController.check">
        <v-form ref="form">
          <v-card-title class="headline">
            <v-spacer />
            Gerar código para serial {{ releaseController.equipment.nome }}
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              v-if="releaseController.macs.length > 0"
              autocomplete="off"
              class="pt-4"
              filled
              :items="releaseController.macs"
              item-text="mac"
              item-value="id"
              label="Mac"
              v-model="releaseController.equipment"
              return-object
              :rules="[v => !!v || 'Escolha o endereço mac']"
            ></v-autocomplete>
            <v-text-field
              autocomplete="off"
              :class="{'pt-4': releaseController.macs.length == 0}"
              filled
              v-model="releaseController.release.codigo"
              label="Rede"
              return-object
              :rules="[v => !!v || 'Escolha a rede']"
            ></v-text-field>
            <v-select
              label="Motivo"
              filled
              dense
              :items="releaseController.motivos"
              v-model="releaseController.release.descricao_motivo"
              :rules="[v => !!v || 'Informe o motivo da liberação']"
            >
            </v-select>
            <v-textarea
              :rules="[v => !!v || 'Preencha a observação']"
              filled
              :counter="500"
              v-model="releaseController.release.observacao"
              label="Observação"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="red"
              @click="releaseController.dialog = false"
            >Cancelar</v-btn>

            <v-btn
              color="primary"
              @click="releaseController.validateForm()"
              :loading="releaseController.loading"
              text
            >Confirmar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card
        v-else
        class="py-10"
      >
        <div
          v-if="releaseController.release.codigo"
          style="width: 100%; margin: auto; text-align: center;"
        >

          <span style="font-weight: bold; padding-top: 10px;">Motivo: {{
            releaseController.release.codigo.motivo
          }}</span>
          <br>
          <span>Código da rede</span>
          <br>
          <br />
          <br />
          <span style="color: red; font-weight: bold; font-size: 40px; padding-top: 10px;">{{
            releaseController.release.codigo.senha
          }}</span>
        </div>

        <v-card-actions class="pt-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="red"
            @click="releaseController.close()"
          >
            <v-icon
              small
              left
            >mdi-delete</v-icon>fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    releaseController: null,
  },
  mounted() {
    this.releaseController.init(this);
  },
};
</script>
