import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async create(user) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/user`, user.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(user);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async index() {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/user`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data.map((user) => {
        user.senha = null
        return user
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index();

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async update(user) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/user/${user.id}`, user.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(user);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async updateVisualizations(payload, id) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/user/${id}/visualization`, payload, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.updateVisualizations(payload, id);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async delete(user) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.delete(`${url}/user/${user.id}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.delete(user);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
