import moment from "moment";

class Filter {
  constructor({
    id_cliente = null,
    serial = null,
    placa = null,
    rede = null,
    id_modelo = null,
    id_usuario = null,
    inicio = moment().subtract(1, 'months').format('DD/MM/YYYY'),
    fim = moment().format('DD/MM/YYYY')
  }) {
    this.idCliente = id_cliente;
    this.serial = serial
    this.placa = placa
    this.rede = rede;
    this.idModelo = id_modelo;
    this.idUsuario = id_usuario;
    this.inicio = inicio;
    this.fim = fim;
  }

  toJson() {
    return {
      id_cliente: this.idCliente,
      serial: this.serial,
      placa: this.placa,
      rede: this.rede,
      id_modelo: this.idModelo,
      id_usuario: this.idUsuario,
      inicio: this.inicio,
      fim: this.fim
    };
  }
}

export default Filter;