class Code {
  constructor({
    id = 0,
    nome = null,
    senha = null
  }) {
    this.id = id;
    this.nome = nome;
    this.senha = senha;
  }

  toJson() {
    return {
      id: this.id,
      nome: this.nome,
      senha: this.senha
    };
  }

  getNome() {
    return {
      nome: this.nome,
    };
  }
}

export default Code;
