import client from "../data/repository/client";
import equipment from "../data/repository/equipment";
import model from "../data/repository/model";
import vehicle from "../data/repository/vehicle";
import equipmentType from "../data/repository/equipment-type";
import Equipment from "../domain/model/equipment";
import UtilController from "./util";
import onChange from "on-change";
import QRCodeReport from "./qrCodeReport";
import XLSX from "xlsx";

export default class EquipmentController {
  dialog = false;
  headers = [
    {
      text: "Serial",
      align: "start",
      sortable: true,
      value: "nome"
    },
    {
      text: "Modelo",
      align: "start",
      sortable: true,
      value: "modelo"
    },
    {
      text: "Data cadastro",
      align: "start",
      sortable: true,
      value: "data_criacao"
    },
    {
      text: "Data alteração placa",
      align: "start",
      sortable: true,
      value: "data_alteracao_placa"
    },
    {
      text: "Usuário",
      align: "start",
      sortable: true,
      value: "usuario"
    },
    {
      text: "Placa",
      align: "start",
      sortable: true,
      value: "placa"
    },
    {
      text: "Tipo",
      align: "start",
      sortable: true,
      value: "tipo"
    },
    {
      text: "Cliente",
      align: "start",
      sortable: true,
      value: "nome_cliente"
    },
    {
      text: "Endereço mac",
      align: "start",
      sortable: true,
      value: "mac"
    },
    {
      text: "Ativo",
      align: "start",
      sortable: true,
      value: "ativo"
    },
    {
      text: "Ações",
      align: "end",
      value: "actions"
    }
  ];
  equipments = [];
  vehicles = [];
  types = [];
  models = [];
  clients = null;
  search = null;
  equipment = new Equipment({});
  loading = false;
  dialogCode = false;
  filter = 0;
  comPlaca = 0;
  semPlaca = 0;
  showMac = false;
  dialogPlaca = false;
  dialogCliente = false;

  comboClients = []
  idCliente = null

  selecionados = []
  habilitaTrocarCliente = false

  mask = ["######", "#######", "########"];
  rules = [
    v => !!v || "Preencha o serial",
    v => (v && v.length <= 8) || "Serial deve ter no maximo 8 digitos",
    v => (v && v.length >= 6) || "Serial deve ter no minimo 6 digitos"
  ];

  constructor() {
    this.watch = onChange(this, function(path, value, previousValue, name) {
      if (path == "filter") {
        this.selecionados = []
        this.index();
      }

      // if (path == 'search') {
      //   this.selecionados = []
      // }

      if (path == "selecionados") {
        this.habilitaTrocarCliente = this.selecionados.length > 0 
      }

      if (path == "equipment.idTipoEquipamento") {
        this.handleMac(this.equipment.idTipoEquipamento);
      }
    });
  }

  validateForm() {
    if (this.context.$refs.form.validate()) {
      if (this.equipment.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  init(context) {
    this.context = context;

    this.context.$bus.$on("on:accept", async () => {
      try {
        await equipment.delete(this.equipment);
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.index();
      }
    });
  }

  async create() {
    try {
      this.loading = true;

      if (this.clients == null) {
        this.equipment.idCliente = new UtilController().getIDClient();
      }

      await equipment.create(this.equipment);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async update() {
    if (this.equipment.idVehicle == undefined) {
      this.equipment.idVehicle = null;
    }

    try {
      this.loading = true;
      await equipment.update(this.equipment);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.dialogPlaca = false;
      this.index();
    }
  }

  async updatePlate() {
    if (this.equipment.idVehicle == undefined) {
      this.equipment.idVehicle = null;
    }

    try {
      this.loading = true;
      await equipment.updatePlate(this.equipment);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.dialogPlaca = false;
      this.index();
    }
  }

  async deleteItem(item) {
    this.equipment = new Equipment(item);
    window.alertController.confirm({ message: "Deseja realmente excluir o registro?" });
  }

  async index() {
    try {
      this.selecionados = []
      this.loading = true;
      this.equipments = await equipment.index(this.filter);

      const { equipments } = new UtilController().getItemsVisualizacoesUsuario();

      if (equipments.length > 0) {
        this.equipments = this.equipments.filter(equipment => equipments.includes(equipment.id));
      }

      this.comPlaca = 0;
      this.semPlaca = 0;

      this.equipments.forEach(element => {
        if (element?.id_veiculo != null) {
          this.comPlaca++;
        } else {
          this.semPlaca++;
        }
      });
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
    }
  }

  async setEquipment(value) {
    try {
      const clients = await client.index(0);
      this.clients = clients;
    } catch (error) {
      this.clients = null;
    }

    try {
      const [models, vehicles, types] = await Promise.all([
        model.index(),
        vehicle.index(0),
        equipmentType.index(0)
      ]);

      if (value) {
        this.equipment = new Equipment(value);
      } else {
        this.equipment = new Equipment({});
      }

      this.models = models;
      this.vehicles = vehicles;
      this.types = types;

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setPlaca(value) {
    try {
      const vehicles = await vehicle.index(0);

      this.equipment = new Equipment(value);

      this.vehicles = vehicles;

      this.dialogPlaca = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  handleMac(id) {
    if (id > 1 && id < 4) {
      this.showMac = true;
    } else {
      this.showMac = false;
    }
  }

  async atualizarCliente() {
    try {
      const clients = await client.index(0);
      this.comboClients = clients
      this.dialogCliente = true
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async updateClient() {
    try {
      const idsEquipamentos = this.selecionados.map((selecionado) => selecionado.id);
      const payload = {
        equipamentos: idsEquipamentos,
        cliente: this.idCliente
      }

      await equipment.updateClient(payload)

      this.dialogCliente = false
      this.idCliente = null
      this.selecionados = []
      this.index()
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  qrCode() {
    const report = new QRCodeReport()
    report.call([...this.selecionados])
  }

  xlsx() {
    var dados = XLSX.utils.json_to_sheet(this.equipments);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dados, "dados");
    XLSX.writeFile(wb, `equipamentos.xlsx`);
  }
}
