import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async create(code) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/code`, code.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(code);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async index() {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/code`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(client);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async update(code) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/code/${code.id}`, code.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(code);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async delete(code) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.delete(`${url}/code/${code.id}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.delete(code);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async get(rede) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/code/rede/${rede}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.get(rede);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
