import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async index(filter) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.post(`${url}/release/rel`, filter.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index(filter);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async create(release) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/release`, release.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(release);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
