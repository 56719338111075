<template>
  <v-app style="background-color: #f9f2e8">
    <vue-particles
      style="position: fixed; top: 0; left: 0; width: 100%; height: 100vh"
      color="#043844"
      :particleOpacity="0.4"
      linesColor="#043844"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="5"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="4"
      :hoverEffect="true"
      hoverMode="repulse"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>
    <v-container fill-height>
      <v-layout
        justify-center
        align-center
      >
        <v-flex
          lg4
          class="text-center"
        >
          <v-img
            class="mb-10"
            style="max-height: 100px"
            contain
            :src="$imageLogin"
          ></v-img>
          <v-card
            elevation="8"
            style="border-radius: 20px"
            class="py-12 pa-5"
            v-on:keyup.enter="sessionController.login()"
          >
            <v-card-text v-if="!sessionController.trocarSenha">
              <!-- <span style="color: red; font-weight: bold">{{message}}</span> -->

              <v-text-field
                :disabled="sessionController.loading"
                filled
                dense
                v-model="sessionController.usuario.nomeUsuario"
                label="Usuário"
              ></v-text-field>
              <v-text-field
                :disabled="sessionController.loading"
                filled
                dense
                v-model="sessionController.usuario.senha"
                type="password"
                label="Senha"
              ></v-text-field>
              <v-btn
                :loading="sessionController.loading"
                height="50"
                block
                color="primary"
                @click="sessionController.login()"
              >Login</v-btn>
            </v-card-text>
            <v-card-text v-else>
              <v-text-field
                :disabled="sessionController.loading"
                filled
                dense
                v-model="sessionController.usuario.senha"
                type="password"
                label="Nova senha"
              ></v-text-field>
              <v-btn
                :loading="sessionController.loading"
                height="50"
                block
                color="primary"
                @click="sessionController.trocaSenha()"
              >Trocar senha</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn
      @click="intro"
      color="primary"
      style="position: fixed; top: 10px; left: 10px;"
      fab
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import SessionController from "@/mobx/store/session";
import IntroVue from "./Intro.vue";

export default {
  data: () => ({
    sessionController: new SessionController(),
    defaultController: window.defaultController,
  }),
  methods: {
    intro() {
      this.defaultController.setDefault(IntroVue);
    },
  },
};
</script>

<style scoped>
#grad {
  background-image: linear-gradient(#023745, #fae526);
}
</style>
