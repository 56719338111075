<template>
  <v-app style="background-color: #f9f2e8">
    <vue-particles
      style="position: fixed; top: 0; left: 0; width: 100%; height: 100vh"
      color="#043844"
      :particleOpacity="0.4"
      linesColor="#043844"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="5"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="4"
      :hoverEffect="true"
      hoverMode="repulse"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>
    <v-container fill-height>
      <v-layout justify-center align-center>
        <v-flex lg4 class="text-center">
          <v-avatar tile height="200" width="200" class="mb-10">
            <v-img contain src="/astra.png"></v-img>
          </v-avatar>
          <v-card elevation="0" color="transparent" style="border-radius: 20px" class="py-12 pa-5">
            <v-card-text>
              <!-- <span style="color: red; font-weight: bold">{{message}}</span> -->
              <v-btn fab @click="liberar" class="mb-2" height="200" width="200" color="primary">Liberar veículo</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    defaultController: window.defaultController
  }),
  methods: {
    liberar() {
      axios.get("http://192.168.4.1");
    }
  }
};
</script>

<style scoped>
#grad {
  background-image: linear-gradient(#023745, #fae526);
}
</style>
