import code from "../data/repository/code";
import release from "../data/repository/release";
import vehicle from "../data/repository/vehicle";
import client from "../data/repository/client";
import equipment from "../data/repository/equipment";
import model from "../data/repository/model";
import user from "../data/repository/user";
import Release from "../domain/model/release";
import Equipment from "../domain/model/equipment";
import Filter from "../domain/model/filter";
import moment from "moment";
import onChange from "on-change";

export default class ReleaseController {
  motivos = ["Instalação nova", "Manutenção no veículo", "Bloqueado"]

  headers = [
    {
      text: "Data",
      align: "start",
      value: "data_criacao"
    },
    {
      text: "Placa",
      align: "start",
      value: "placa"
    },
    {
      text: "Serial",
      align: "start",
      value: "equipamento"
    },
    {
      text: "Rede",
      align: "start",
      value: "rede"
    },
    {
      text: "Cliente",
      align: "start",
      value: "cliente"
    },
    {
      text: "Usuário",
      align: "start",
      value: "usuario"
    },
    {
      text: "Motivo",
      align: "start",
      value: "motivo"
    },
    {
      text: "Descrição motivo",
      align: "start",
      value: "descricao_motivo"
    },
    {
      text: "OBS",
      align: "start",
      value: "observacao"
    }
  ];
  dialog = false;
  releases = [];
  release = new Release({});
  clients = [];
  equipments = [];
  vehicles = [];
  codes = [];
  models = [];
  users = [];
  equipment = new Equipment({});
  filter = new Filter({});
  loading = false;
  check = false;
  search = null;
  macs = [];

  ini = false;
  fim = false;
  dateIni = moment()
    .subtract(1, "months")
    .format("YYYY-MM-DD");
  dateFim = moment().format("YYYY-MM-DD");
  context = null;

  en = {
    "2": "GENÉRICO",
    "3": "JAMMER",
    "4": "PAINEL",
    "5": "SENSOR",
    "6": "REMOTO"
  }

  constructor() {
    this.watch = onChange(this, function (path, value, previousValue, name) {
      if (path == "dateIni") {
        this.filter.inicio = moment(value).format("DD/MM/YYYY");
      }

      if (path == "dateFim") {
        this.filter.fim = moment(value).format("DD/MM/YYYY");
      }
    });
  }

  validateForm() {
    if (this.context.$refs.form.validate()) {
      this.confirm();
    }
  }

  init(context) {
    this.context = context;
  }

  async confirm() {
    try {
      this.loading = true;

      const serial = this.equipment.nome.slice(-4).split("");
      const rede = await code.get(this.release.codigo);

      const codigo = rede.senha.split("");

      const motivo = this.release.codigo.replace("WIFI", "")[0];

      const cd = `${serial[0]}${codigo[0]}${serial[1]}${codigo[1]}${serial[2]}${codigo[2]}${serial[3]}${codigo[3]}`;
      const format = cd.split("");
      const formated = `${format[0]}${format[1]} ${format[2]}${format[3]}${format[4]} ${format[5]}${format[6]}${format[7]}`;

      this.release.codigo = {
        id: rede.id,
        senha: formated,
        motivo: this.en[motivo]
      };

      this.check = true;

      await release.create(this.release);

      setTimeout(() => {
        this.dialog = false;

        this.codes = [];
        this.check = false;
      }, 20000);

      // var socket = io('http://realtime.astrainovacoes.com.br', {
      //   transports: ['polling']
      // });

      // (cd);

      // socket.on('connect', function () {
      //   ("connected");
      //   socket.emit('lib', JSON.stringify({ wifi: `WIFI${rede.nome}`, codigo: cd }));
      // });

      // await state.dispatch('index');
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
    }
  }

  async index() {
    try {
      this.loading = true;
      this.releases = await release.index(this.filter);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.dialog = false;
      this.loading = false;
    }
  }

  close() {
    this.codes = [];
    this.check = false;
    this.dialog = false;
  }

  async setFilter() {
    try {
      this.loading = true;
      const [clients, equipments, vehicles, codes, models, users] = await Promise.all([
        client.index(2),
        equipment.index(2),
        vehicle.index(2),
        code.index(),
        model.index(),
        user.index()
      ]);

      this.codes = codes;
      this.clients = clients;
      this.equipments = equipments;
      this.vehicles = vehicles;
      this.models = models;
      this.users = users;

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
    }
  }

  async setCode({ value, id = 0 }) {
    this.macs = [];
    if (id == 0) {
      this._setRelease({ value: value });
    } else {
      const e = await vehicle.indexEquipment(id);
      if (e.length > 1) {
        this.macs = e;
        for (let m of this.macs) {
          if (m.mac == null) {
            m.mac = "Jammer Principal (sem mac)";
          }
        }

        this._setRelease({ value: this.macs.filter((item) => item.mac == "Jammer Principal (sem mac)")[0] });
      } else if (e.length == 1) {
        this._setRelease({ value: e[0] });
      } else {
        window.alertController.show({ message: "Nenhum equipamento cadastrado para esse veículo!" });
      }
    }
  }

  _setRelease({ value }) {
    this.equipment = {}
    if (value.length != undefined) {
      this.release = new Release({
        id_codigo: 0
      });
    } else {
      this.equipment = new Equipment(value);
      this.release = new Release({
        id_codigo: 0,
        id_veiculo: this.equipment.idVeiculo,
        id_equipamento: this.equipment.id
      });
    }

    this.dialog = true;
  }
}
