<template>
  <v-app style="background-color: #f9f2e8">
    <vue-particles
      style="position: fixed; top: 0; left: 0; width: 100%; height: 100vh"
      color="#043844"
      :particleOpacity="0.4"
      linesColor="#043844"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="5"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="4"
      :hoverEffect="true"
      hoverMode="repulse"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>
    <v-container fill-height>
      <v-layout
        justify-center
        align-center
      >
        <v-flex
          lg4
          class="text-center"
        >
          <v-img
            class="mb-10"
            style="max-height: 100px"
            contain
            :src="$imageLogin"
          ></v-img>
          <v-card
            elevation="0"
            color="transparent"
            style="border-radius: 20px"
            class="py-12 pa-5"
          >
            <v-card-text>
              <!-- <span style="color: red; font-weight: bold">{{message}}</span> -->
              <v-btn
                @click="login"
                class="mb-2"
                height="50"
                block
                color="primary"
              >Empresa</v-btn>
              <v-btn
                @click="motorista"
                height="50"
                block
                color="white"
              >Liberar veículo</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Login from "@/views/Login.vue";
import Driver from "./Driver.vue";
import axios from "axios";

export default {
  data: () => ({
    defaultController: window.defaultController,
  }),
  methods: {
    login() {
      this.defaultController.setDefault(Login);
    },
    motorista() {
      let wnd = window.open("http://192.168.4.1");
      setTimeout(() => {
        wnd.close();
      }, 2000);
      // this.defaultController.setDefault(Driver)
    },
  },
};
</script>

<style scoped>
#grad {
  background-image: linear-gradient(#023745, #fae526);
}
</style>
