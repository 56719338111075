<template>
  <v-app>
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md3 v-if="utilController.checkPermissions(7, 4)">
          <v-card height="150" @click="usuarios">
            <v-layout justify-center align-center>
              <span class="primary--text" style="font-size: 25px; padding-top: 50px">
                <v-icon color="primary" x-large left>mdi-account</v-icon>Usuários
              </span>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 v-if="utilController.checkPermissions(2, 4)">
          <v-card height="150" @click="equipamentos">
            <v-layout justify-center align-center>
              <span class="primary--text" style="font-size: 25px; padding-top: 50px">
                <v-icon color="primary" x-large left>mdi-access-point-network</v-icon>Equipamentos
              </span>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 v-if="utilController.checkPermissions(4, 4)">
          <v-card height="150" @click="veiculos">
            <v-layout justify-center align-center>
              <span class="primary--text" style="font-size: 25px; padding-top: 50px">
                <v-icon color="primary" x-large left>mdi-truck</v-icon>Veículos
              </span>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 v-if="utilController.checkPermissions(1, 4)">
          <v-card height="150" @click="clientes">
            <v-layout justify-center align-center>
              <span class="primary--text" style="font-size: 25px; padding-top: 50px">
                <v-icon color="primary" x-large left>mdi-account</v-icon>Clientes
              </span>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import UtilController from "@/mobx/store/util";
import Equipment from "@/views/Equipment.vue";
import Client from "@/views/Client.vue";
import Vehicle from "@/views/Vehicle.vue";
import User from "./User.vue";

export default {
  data: () => ({
    homeController: window.homeController,
    utilController: new UtilController()
  }),
  methods: {
    equipamentos() {
      this.homeController.setDefault(Equipment)
    },
    veiculos() {
     this.homeController.setDefault(Vehicle)
    },
    clientes() {
      this.homeController.setDefault(Client)
    },
    usuarios() {
      this.homeController.setDefault(User)
    }
  }
};
</script>
