import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs

class QRCodeReport {
    async call(equipamentos) {

        const content = []
        const tamanho = equipamentos.length > 4 ? 4 : equipamentos.length
        const qtd = parseInt(equipamentos.length / 4)

        for (let j = 0; j < qtd; j++) {
            let line = []

            for (let i = 0; i < tamanho; i++) {
                let equipamento = equipamentos.pop()
                // line.push({
                //     width: 30,
                //     margin: [(i + 1) * 10, 10, 0, 0],
                //     text: equipamento.nome,
                //     fontSize: 8
                // })
                line.push({
                    width: 40 + (i + 1) * 10,
                    margin: [(i + 1) * 10, 0, 0, 0],
                    stack: [
                        { text: equipamento.nome, fontSize: 8 },
                        {
                            width: 60,

                            qr: equipamento.nome, fit: '60'
                        },
                    ],
                    unbreakable: true
                })

                line.push({
                    width: 20,
                    margin: [10, 20, 0, 0],
                    image: 'snow',
                    width: 60,
                    height: 20
                })
            }
            content.push({
                margin: 10,
                columns: line,
            })
        }

        console.log(content);

        try {
            var docDefinition = {
                pageMargins: [15, 25, 25, 35],
                header: function (currentPage, pageCount, pageSize) {
                    return [

                    ]
                },
                footer: function (currentPage, pageCount) {
                    return {
                        margin: 10,
                        columns: [
                            {
                                fontSize: 9,
                                text: [
                                    {
                                        text:
                                            '© Astra inovacoes. Pagina ' +
                                            currentPage.toString() +
                                            ' de ' +
                                            pageCount + ' | ' + moment().format("DD/MM/YYYY - HH:mm"),
                                    },
                                ],
                                alignment: 'center',
                            },
                        ],
                    }
                },
                pageOrientation: 'portrait',
                content: content,
                images: {
                    // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
                    snow: window.location.href + window.imageLogin,

                }
            }
            pdfMake.createPdf(docDefinition).open()
        } catch (error) {
            throw error
        }
    }
}

export default QRCodeReport