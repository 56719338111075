import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';
import vuetify from './plugins/vuetify';
import maps from './plugins/maps';
import moment from 'moment'
import AlertController from "@/mobx/store/alert"
import DefaultController from "@/mobx/store/default"
import HomeController from './mobx/store/home';

import EventBus from './event-bus'

import VueTheMask from 'ke-the-mask'
Vue.use(VueTheMask)

import VueParticles from 'vue-particles'
import './registerServiceWorker'

Vue.use(VueParticles)

window.alertController = new AlertController()
window.defaultController = new DefaultController()
window.homeController = new HomeController()

Vue.prototype.$moment = moment

Vue.prototype.$bus = EventBus

Vue.config.productionTip = false;

Vue.prototype.$imageLogin = '/dlog.png'
window.imageLogin = '/dlog.png'

new Vue({
  router,
  // store,
  vuetify,
  maps,
  render: (h) => h(App),
}).$mount('#app');
