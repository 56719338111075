import model from "../data/repository/model";
import Model from "../domain/model/model";

export default class ModelController {
  dialog = false;
  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "nome"
    },
    {
      text: "Versão firmware",
      align: "start",
      sortable: true,
      value: "versao_firmware"
    },
    {
      text: "Ações",
      align: "end",
      value: "actions"
    }
  ];
  models = [];
  search = null;
  model = new Model({});
  loading = false;

  context = null;

  validateForm() {
    // if (this.context.$refs.form.validate()) {
    if (this.model.id) {
      this.update();
    } else {
      this.create();
    }
    // }
  }

  init(context) {
    this.context = context;

    this.context.$bus.$on("on:accept", async () => {
      try {
        await model.delete(this.model);
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.index();
      }
    });
  }

  async getCityes() {
    try {
      this.cityes = await citye.index(this.city);
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async create() {
    try {
      if (this.context.$refs.formModule.validate()) {
        this.loading = true;
        await model.create(this.model);

        this.dialog = false;
        this.index();
      }
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;

    }
  }

  async update() {
    try {
      if (this.context.$refs.formModule.validate()) {
        this.loading = true;
        await model.update(this.model);
        this.dialog = false;
        this.index();
      }
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;


    }
  }

  async deleteItem(item) {
    this.model = new Model(item);
    window.alertController.confirm({ message: "Deseja realmente excluir o registro?" });
  }

  async index() {
    try {
      this.models = await model.index();
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setModel(model) {
    console.log(model);
    try {
      if (model) {
        this.model = new Model(model);
      } else {
        this.model = new Model({});
      }

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }
}
