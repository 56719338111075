var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"x12":""}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"pr-4"},[_vm._v("Modelos de equipamento")]),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Pesquisar modelo","single-line":"","filled":"","dense":"","hide-details":""},model:{value:(_vm.modelController.search),callback:function ($$v) {_vm.$set(_vm.modelController, "search", $$v)},expression:"modelController.search"}}),(_vm.utilController.checkPermissions(3, 1))?_c('v-btn',{attrs:{"color":"primary ml-3"},on:{"click":function($event){return _vm.modelController.setModel(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Novo ")],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","fixed-header":"","height":"75vh","headers":_vm.modelController.headers,"items":_vm.modelController.models,"search":_vm.modelController.search,"sort-by":"calories","footer-props":{
              itemsPerPageOptions: [30, 60, 100],
              itemsPerPageText: 'Linhas por pagina',
            }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.utilController.checkPermissions(3, 2))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""},on:{"click":function($event){return _vm.modelController.setModel(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.utilController.checkPermissions(3, 3))?_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.modelController.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticStyle:{"height":"66vh"},attrs:{"align":"center","justify":"center"}},[_vm._v("Sem dados")])],1)]},proxy:true}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500"},model:{value:(_vm.modelController.dialog),callback:function ($$v) {_vm.$set(_vm.modelController, "dialog", $$v)},expression:"modelController.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Novo modelo")]),_c('v-card-text',[_c('v-form',{ref:"formModule"},[_c('v-text-field',{attrs:{"dense":"","filled":"","rules":[function (v) { return !!v || 'Preencha o nome'; }],"label":"Nome"},model:{value:(_vm.modelController.model.nome),callback:function ($$v) {_vm.$set(_vm.modelController.model, "nome", $$v)},expression:"modelController.model.nome"}}),_c('v-text-field',{attrs:{"dense":"","filled":"","label":"Versão firmware"},model:{value:(_vm.modelController.model.versaoFirmware),callback:function ($$v) {_vm.$set(_vm.modelController.model, "versaoFirmware", $$v)},expression:"modelController.model.versaoFirmware"}}),_c('v-textarea',{attrs:{"dense":"","filled":"","label":"Descrição de uso do modelo","rules":[
              function (v) { return !!v || 'Preencha o campo'; },
              function (v) { return !!v && v.length <= 1000 ||
                'A descrição não pode ter mais que 1000 caracteres'; } ],"counter":1000},model:{value:(_vm.modelController.model.descricaoUso),callback:function ($$v) {_vm.$set(_vm.modelController.model, "descricaoUso", $$v)},expression:"modelController.model.descricaoUso"}})],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.modelController.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Cancelar")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.modelController.loading},on:{"click":function($event){return _vm.modelController.validateForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }