import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async create(model) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/model`, model.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(model);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async index() {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/model`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index()

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async update(model) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/model/${model.id}`, model.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(model)

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async delete(model) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.delete(`${url}/model/${model.id}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.delete(model)

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
