import Intro from "@/views/Intro.vue";

export default class DefaultController {
  default = {
    comp: Intro
  };

  async setDefault(component) {
    this.default = {
      comp: component
    };
  }
}
