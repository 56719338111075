class Equipment {
  constructor({
    id = 0,
    nome = null,
    id_modelo = null,
    id_veiculo = null,
    data_criacao = null,
    id_usuario = 0,
    id_tipo_equipamento = null,
    ativo = true,
    mac = null,
    id_cliente = null,
    nome_cliente = null
  }) {
    this.id = id;
    this.nome = nome;
    this.idModelo = id_modelo;
    this.dataCriacao = data_criacao;
    this.idUsuario = id_usuario;
    this.ativo = ativo;
    this.idVeiculo = id_veiculo;
    this.idTipoEquipamento = id_tipo_equipamento;
    this.mac = mac;
    this.idCliente = id_cliente
    this.nomeCliente = nome_cliente
  }

  toJson() { 
    return {
      id: this.id,
      nome: this.nome,
      id_modelo: this.idModelo,
      data_criacao: this.dataCriacao,
      id_usuario: this.idUsuario,
      ativo: this.ativo,
      id_veiculo: this.idVeiculo ? this.idVeiculo : null,
      id_tipo_equipamento: this.idTipoEquipamento,
      mac: this.idTipoEquipamento > 1 ? this.mac : null,
      id_cliente: this.idCliente
    };
  }

  getNome() {
    return {
      nome: this.nome
    };
  }
}

export default Equipment;
