import code from "../data/repository/code";
import Code from "../domain/model/code";

export default class CodeController {
  dialog = false
  headers = [
    {
      text: 'Código',
      align: 'start',
      sortable: true,
      value: 'nome',
    },
    {
      text: 'Senha',
      align: 'start',
      sortable: true,
      value: 'senha',
    },
    {
      text: 'Ações',
      align: 'end',
      value: 'actions',
    },
  ]
  codes = []
  search = null
  code = new Code({})
  loading = false

  context = null;

  validateForm() {
    // if (this.context.$refs.form.validate()) {
      if (this.code.id) {
        this.update();
      } else {
        this.create();
      }
    // }
  }

  init(context) {
    this.context = context;

    this.context.$bus.$on("on:accept", async () => {
      try {
        await code.delete(this.code);
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.index();
      }
    });
  }

  async getCityes() {
    try {
      this.cityes = await citye.index(this.city);
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async create() {
    try {
      this.loading = true;
      await code.create(this.code);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async update() {
    try {
      this.loading = true;
      await code.update(this.code);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async deleteItem(item) {
    this.code = new Code(item);
    window.alertController.confirm({ message: "Deseja realmente excluir o registro?" });
  }

  async index() {
    try {
      this.codes = await code.index();
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setCode(code) {
    try {
      if (code) {
        this.code = new Code(code);
      } else {
        this.code = new Code({});
      }

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }
}
