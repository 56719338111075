import * as jwtDecode from "jwt-decode";

export default class UtilController {
  formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  parseDate(date) {
    if (!date) return null;

    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  compare(key) {
    return (a, b) => {
      const genreA = a[key].toUpperCase();
      const genreB = b[key].toUpperCase();

      let comparison = 0;
      if (genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
      return comparison;
    };
  }

  checkPermissions(permissao, acao) {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    if (user.id_grupo_usuario == 1) {
      return true;
    }

    for (let permission of user.permissions) {
      if (permission.id_permissao == permissao && permission.id_acao == acao) {
        return true;
      }
    }

    return false;
  }

  checkField(permissao, acao, field) {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    if (user.id_grupo_usuario == 1) {
      return true;
    }

    for (let permission of user.permissions) {
      if (permission.id_permissao == permissao && permission.id_acao == acao) {
        return permission.campos.split(",").indexOf(field) > -1;
      }
    }

    return false;
  }

  getIDClient() {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    return user.id_cliente;
  }

  getUsuario() {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    return user.nome_usuario;
  }

  getUserEmp() {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    return user?.nome_cliente ?? "";
  }

  trocarSenha() {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    return user?.trocar_senha ?? false;
  }

  getItemsVisualizacoesUsuario() {
    const token = JSON.parse(localStorage.getItem("token"));
    var user = jwtDecode(token.token);

    return {
      equipments: user?.equipamentos_visiveis ? user?.equipamentos_visiveis?.split(",").map(id => parseInt(id)) : [],
      vehicles: user?.veiculos_visiveis ? user?.veiculos_visiveis?.split(",").map(id => parseInt(id)) : []
    };
  }
}
