import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async create(equipment) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/equipment`, equipment.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      await session.refresh();
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(equipment);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async index(status) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/equipment?status=${status}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index(status);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async update(equipment) {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await axios.put(`${url}/equipment/${equipment.id}`, equipment.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(equipment);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async updatePlate(equipment) {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await axios.put(`${url}/equipment/${equipment.id}/plate`, equipment.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(equipment);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async delete(equipment) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.delete(`${url}/equipment/${equipment.id}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.delete(equipment);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async updateClient(payload) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/equipment/client/log`, payload, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      console.log(error);
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.updateClient(payload);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
