class Vehicle {
  constructor({
    id = 0,
    placa = null,
    id_cliente = 0,
    ativo = true,
    modelo = null,
    ano = null,
    qtdEquip = 0,
    equipamentos = []
  }) {
    this.id = id;
    this.ativo = ativo;
    this.placa = placa;
    this.modelo = modelo;
    this.ano = ano;
    this.idCliente = id_cliente
    this.qtdEquip = qtdEquip
    this.equipamentos = equipamentos
  }

  toJson() {
    return {
      id: this.id,
      id_cliente: this.idCliente,
      placa: this.placa.toUpperCase(),
      ativo: this.ativo,
      modelo: this.modelo,
      ano: this.ano,
      equipamentos: this.equipamentos
    };
  }

  getNome() {
    return {
      nome: this.nome,
    };
  }
}

export default Vehicle;
