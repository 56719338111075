import Dashboard from '@/views/Dashboard.vue';

export default class HomeController {
  default = {
    comp: Dashboard
  };

  async setDefault(component) {
    this.default = {
      comp: component
    };
  }
}
