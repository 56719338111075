class User {
  constructor({
    id = 0,
    nome_usuario = null,
    email = null,
    senha = null,
    id_cliente = null,
    id_grupo_usuario = null,
    equipamentos_visiveis = null,
    veiculos_visiveis = null,
    revogado = false,
    trocar_senha = true
  }) {
    this.id = id;
    this.nomeUsuario = nome_usuario;
    this.email = email;
    this.senha = senha;
    this.idCliente = id_cliente
    this.idGrupoUsuario = id_grupo_usuario
    this.equipamentosVisiveis = equipamentos_visiveis
    this.veiculosVisiveis = veiculos_visiveis
    this.revogado = revogado
    this.trocarSenha = trocar_senha
  }

  loginJson() {
    return {
      nome_usuario: this.nomeUsuario,
      senha: this.senha,
    };
  }

  toJson() {
    return {
      id: this.id,
      nome_usuario: this.nomeUsuario,
      email: this.email,
      senha: this.senha,
      id_cliente: this.idCliente ? this.idCliente : null,
      id_grupo_usuario: this.idGrupoUsuario,
      revogado: this.revogado,
      trocar_senha: this.trocarSenha
    };
  }
}

export default User;
