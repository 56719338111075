<template>
  <v-app>
    <v-container fluid>
      <v-layout justify-center align-center>
        <v-flex x12 class="text-center">
          <v-card>
            <v-card-title>
              <span class="pr-4">Relatório de geração de senhas</span>
              <v-spacer></v-spacer>
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                dense
                v-model="releaseController.search"
                filled
                hide-details
              ></v-text-field>
              <v-btn :loading="releaseController.loading" color="primary ml-3" @click="releaseController.setFilter(null);">
                <v-icon left>mdi-plus</v-icon>Filtro
              </v-btn>
            </v-card-title>
            <v-data-table
              dense
              fixed-header
              height="82vh"
              :headers="releaseController.headers"
              :items="releaseController.releases"
              :search="releaseController.search"
              sort-by="calories"
              class="elevation-1"
              disable-pagination
              hide-default-footer
            >
              <template
                v-slot:[`item.data_criacao`]="{ item }"
              >{{$moment.utc(item.data_criacao).format('DD/MM/YYYY HH:mm')}}</template>
              <template v-slot:no-data>
                <v-col cols="12">
                  <v-row align="center" justify="center" style="height: 66vh;">Sem dados</v-row>
                </v-col>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="releaseController.dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Filtro</v-card-title>
        <v-card-text>
          <v-autocomplete
            clearable
            item-text="nome"
            item-value="id"
            filled
            v-model="releaseController.filter.idCliente"
            :items="releaseController.clients"
            label="Cliente"
            dense
          ></v-autocomplete>
          <v-autocomplete
            clearable
            item-text="nome"
            item-value="id"
            filled
            v-model="releaseController.filter.serial"
            :items="releaseController.equipments"
            label="Serial"
            dense
          ></v-autocomplete>
          <v-autocomplete
            clearable
            item-text="placa"
            item-value="id"
            filled
            v-model="releaseController.filter.placa"
            :items="releaseController.vehicles"
            label="Placa"
            dense
          ></v-autocomplete>
          <v-autocomplete
            clearable
            item-text="nome"
            item-value="id"
            filled
            v-model="releaseController.filter.rede"
            :items="releaseController.codes"
            label="Rede"
            dense
          ></v-autocomplete>
          <v-autocomplete
            clearable
            item-text="nome"
            item-value="id"
            filled
            v-model="releaseController.filter.idModelo"
            :items="releaseController.models"
            label="Modelo"
            dense
          ></v-autocomplete>
          <v-autocomplete
            clearable
            item-text="nome_usuario"
            item-value="id"
            filled
            v-model="releaseController.filter.idUsuario"
            :items="releaseController.users"
            label="Usuário"
            dense
          ></v-autocomplete>
          <v-container fluid grid-list-md class="px-0 pt-0">
            <v-layout row wrap>
              <v-flex xs6>
                <v-menu
                  ref="ini"
                  v-model="releaseController.ini"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field readonly dense filled v-model="releaseController.filter.inicio" label="Data Inicial" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="primary" v-model="releaseController.watch.dateIni" no-title @input="releaseController.ini = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="fim"
                  v-model="releaseController.fim"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field filled readonly dense v-model="releaseController.filter.fim" label="Data Final" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="primary" v-model="releaseController.watch.dateFim" no-title @input="releaseController.fim = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-3">
          

          <!-- <v-btn @click="dialog = false" color="red" text><v-icon left>mdi-close</v-icon> Cancelar</v-btn> -->
<!-- <v-spacer></v-spacer> -->
          <v-btn block color="primary" :loading="releaseController.loading" @click="releaseController.index()"><v-icon left>mdi-content-save</v-icon> Filtrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ReleaseController from "@/mobx/store/release";

export default {
  data: () => ({
    releaseController: new ReleaseController()
  }),
  // computed: {
  //   ...mapState({
  //     dialog: state => state.release.dialog,
  //     headers: state => state.release.headers,
  //     releases: state => state.release.releases,
  //     clients: state => state.release.clients,
  //     equipments: state => state.release.equipments,
  //     vehicles: state => state.release.vehicles,
  //     codes: state => state.release.codes,
  //     models: state => state.release.models,
  //     users: state => state.release.users,
  //     loading: state => state.release.loading,
  //     filter: state => state.release.filter
  //   }),
  //   search: {
  //     get() {
  //       return this.$store.state.release.search;
  //     },
  //     set(value) {
  //       this.$store.commit("release/updateSearch", value);
  //     }
  //   }
  // },
  // watch: {
  //   dateIni(val) {
  //     this.filter.inicio = this.formatDate(this.dateIni);
  //   },
  //   dateFim(val) {
  //     this.filter.fim = this.formatDate(this.dateFim);
  //   }
  // },
  mounted() {
    this.releaseController.index();
  },
  // methods: {
  //   ...mapActions("release", ["set", "index"]),
  //   formatDate(date) {
  //     if (!date) return null;

  //     const [year, month, day] = date.split("-");
  //     return `${day}/${month}/${year}`;
  //   }
  // }
};
</script>
