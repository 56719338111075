import client from "../data/repository/client";
import citye from "../data/repository/city";
import Client from "../domain/model/client";
import onChange from "on-change";

export default class ClientController {
  dialog = false;
  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "nome"
    },
    {
      text: "CPF/CNPJ",
      align: "start",
      sortable: true,
      value: "cpf_cnpj"
    },
    {
      text: "Telefone",
      align: "start",
      sortable: true,
      value: "telefone"
    },
    {
      text: "Cidade",
      align: "start",
      sortable: true,
      value: "cidade"
    },
    {
      text: "Ativo",
      align: "start",
      sortable: true,
      value: "ativo"
    },
    {
      text: "Associado",
      align: "start",
      sortable: true,
      value: "associado"
    },
    {
      text: "Associação",
      align: "start",
      sortable: true,
      value: "nome_associacao"
    },
    {
      text: "Veículos",
      align: "end",
      sortable: true,
      value: "qtd_veiculos"
    },
    {
      text: "Ações",
      align: "end",
      value: "actions"
    }
  ];
  clients = [];
  cityes = [];
  city = null;
  search = null;
  client = new Client({});
  loading = false;
  filter = 0;

  cpfcnpj = ["###.###.###-##", "##.###.###/####-##"];
  telefone = ["(##)####-####", "(##)#####-####"];

  context = null;

  constructor() {
    this.watch = onChange(this, function(path, value, previousValue, name) {
      if (path == "filter") {
        this.index();
      }
    });
  }

  validateForm() {
    if (this.context.$refs.form.validate()) {
      if (this.client.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  init(context) {
    this.context = context;

    this.context.$bus.$on("on:accept", async () => {
      try {
        await client.delete(this.client);
      } catch (error) {
        window.alertController.show({ message: error });
      } finally {
        this.index();
      }
    });
  }

  async getCityes() {
    try {
      this.cityes = await citye.index(this.city);
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async create() {
    try {
      this.loading = true;
      await client.create(this.client);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async update() {
    try {
      this.loading = true;
      await client.update(this.client);
    } catch (error) {
      window.alertController.show({ message: error });
    } finally {
      this.loading = false;
      this.dialog = false;
      this.index();
    }
  }

  async deleteItem(item) {
    this.client = new Client(item);
    window.alertController.confirm({ message: "Deseja realmente excluir o registro?" });
  }

  async index() {
    try {
      this.clients = await client.index(this.filter);
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }

  async setClient(client) {
    try {
      if (client) {
        this.client = new Client(client);
        this.cityes = await citye.index(this.client.cidade);
      } else {
        this.client = new Client({});
      }

      this.dialog = true;
    } catch (error) {
      window.alertController.show({ message: error });
    }
  }
}
