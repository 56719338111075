import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async create(vehicle) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.post(`${url}/vehicle`, vehicle.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      await session.refresh()
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.create(vehicle);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async index(status) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/vehicle?status=${status}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index(status);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async indexEquipment(id) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/vehicle/${id}/equipment`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.indexEquipment(id);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async update(vehicle) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.put(`${url}/vehicle/${vehicle.id}`, vehicle.toJson(), {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.update(vehicle);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  },

  async delete(vehicle) {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await axios.delete(`${url}/vehicle/${vehicle.id}`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.delete(vehicle);

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
