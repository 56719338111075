import axios from "axios";
import { url } from "../constants";
import session from "./session";

export default {
  async index() {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axios.get(`${url}/equipment-type`, {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 401:
            await session.refresh();
            return await this.index();

          default:
            throw error.response.data;
        }
      }

      throw error;
    }
  }
};
